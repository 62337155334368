import { useState, useEffect } from "react";
import Config from "../Config";

import "../assets/scss/components/_klarna-cta.scss";

const parseKlarnaAmount = (amount) => {
	return parseFloat(amount)
		.toString()
		.replace(/[^0-9]+/g, "");
};

export const KlarnaCTA = ({ cta = "", theme = "default", purchaseAmount = null, managePaymentsLink = false }) => {
	const [klarnaScriptLoaded, setKlarnaScriptLoaded] = useState(typeof window?.Klarna !== "undefined");

	if (!Config.paymentMethodsEnabled?.klarnaEnabled) {
		return null;
	}

	if (!cta) {
		return null;
	}

	useEffect(() => {
		if (klarnaScriptLoaded) {
			return;
		}
		const script = document.createElement("script");
		script.src = "https://js.klarna.com/web-sdk/v1/klarna.js";
		script.async = true;
		script.dataset.clientId = Config.KLARNA_SDK_KEY;
		script.dataset.environment = Config.KLARNA_ENV;
		script.onload = () => {
			setKlarnaScriptLoaded(true);
		};
		document.body.appendChild(script);
	}, []);

	return (
		<div className={`klarna-cta ${cta}`}>
			<klarna-placement
				data-key={cta}
				data-locale="en-GB"
				{...(purchaseAmount ? { "data-purchase-amount": parseKlarnaAmount(purchaseAmount) } : {})}
				{...(theme === "default" ? { "data-theme": "default" } : {})}
			/>
			{managePaymentsLink && (
				<a
					href="https://app.klarna.com/"
					target="_blank"
				>
					Manage Payments
				</a>
			)}
		</div>
	);
};
