import React from "react";

import { DateInfo, DateDifference } from "../utilities/DateUtils";

export const AvailableFrom = ({ date }) => {
	let availableFrom = "Free Delivery For";
	let availableFromDate = "Tomorrow";

	const dateInformation = DateInfo(date);
	let difference = false;
	if (date !== false) {
		difference = DateDifference(date);
	}

	const now = new Date();
	const cutoff = new Date("2025-03-27T00:00:00");
	if (now < cutoff) return;

	if (dateInformation && difference) {
		if (difference.days === 0) {
			availableFrom = "Delivery From";
			availableFromDate = `Tomorrow, ${dateInformation.pretty.short.day} ${dateInformation.pretty.short.date} ${dateInformation.pretty.short.month}`;
		} else if (dateInformation.pretty && dateInformation.pretty.long.month && dateInformation.pretty.long.date) {
			availableFrom = `Delivery From`;
			availableFromDate = `${dateInformation.pretty.short.month} ${dateInformation.pretty.short.date}`;
		}
	} else {
		availableFrom = "Out Of Stock";
		availableFromDate = "";
	}

	return (
		<div className={`available-from ${date === false ? "out-of-stock" : "in-stock"}`}>
			{availableFrom} <strong>{availableFromDate}</strong>
		</div>
	);
};
