import React from "react";

import { Link } from "react-router-dom";

import "../assets/scss/components/_callout-banner.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { dates } from "../Config";
import { ConfigApi } from "../api/ConfigApi";
import { useAppStore } from "../stores/AppStore";

export const CalloutBanner = () => {
	const { peakConfigs } = useAppStore(({ peakConfigs }) => {
		return { peakConfigs };
	});
	let calloutBannerLink = "/next-day-flowers";
	let calloutBannerText = "Seasonal bouquets with FREE DELIVERY from £19.99.";

	if (peakConfigs?.CHRISTMAS) {
		calloutBannerLink = "/christmas-flowers";
		calloutBannerText = "Christmas Flowers with FREE delivery from £19.99.";
	}

	if (peakConfigs?.MOTHERS_DAY) {
		calloutBannerLink = "/mothers-day-flowers";
		calloutBannerText = "Mother's Day Flowers with FREE delivery from £19.99.";
	}

	return (
		<section className={`callout-banner ${peakConfigs?.CHRISTMAS ? "christmas-callout" : ""}`}>
			<Link to={calloutBannerLink}>
				<span dangerouslySetInnerHTML={{ __html: calloutBannerText }}></span>

				<span>
					{" "}
					Explore the range now! <FontAwesomeIcon icon={faArrowRight} />{" "}
				</span>
			</Link>
		</section>
	);
};
