import React, { useEffect, useRef, useState } from "react";

import { KlarnaApi } from "../api/KlarnaApi";
import { useCartStore } from "../stores/CartStore";

import "../assets/scss/components/_klarna.scss";

export const Klarna = () => {
	const container = useRef(null);

	const [klarnaScriptLoaded, setKlarnaScriptLoaded] = useState(typeof window?.Klarna?.Payments !== "undefined");

	const LoadKlarna = async () => {
		try {
			const clientToken = await KlarnaApi.getClientToken();
			if (!clientToken) {
				return;
			}
			window.Klarna.Payments.Buttons.init({
				client_token: clientToken
			}).load({
				container: container.current,
				theme: "default",
				shape: "default",
				locale: "en-GB",
				on_click: (authorize) => {
					authorize(
						{
							auto_finalize: true,
							collect_shipping_address: false
						},
						null,
						async (result) => {
							if (result.approved === true) {
								const createOrder = await KlarnaApi.finalizeOrder(result.authorization_token);
								if (createOrder?.payment?.redirect_url) {
									// Order creation was successful, redirect to the payment success page
									// Use replace so they can't go back to the payment page
									window.location.replace(createOrder?.payment?.redirect_url);
								} else {
									// Payment was successful but order creation failed, show failure
								}
							} else {
								// Do nothing, payment was not approved
								// This is generally called when the user cancels the payment
								// Since it's a popup we don't need to do anything
							}
						}
					);
				}
			});
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (!klarnaScriptLoaded) {
			const script = document.createElement("script");
			script.src = "https://x.klarnacdn.net/kp/lib/v1/api.js";
			script.async = true;
			script.onload = () => {
				setKlarnaScriptLoaded(true);
				LoadKlarna();
			};
			document.body.appendChild(script);
		} else {
			LoadKlarna();
		}
	}, []);

	return (
		<>
			<div
				id="klarna-payments-container"
				ref={container}
			/>
		</>
	);
};

export default Klarna;
