import React from "react";

import "../assets/scss/components/_message-card-animation.scss";

import MothersDay from "../assets/images/cards/mothers-day-personalised-card.jpg";
import Birthday from "../assets/images/cards/birthday.jpg";
import Sympathy from "../assets/images/cards/sympathy.jpg";
import ThankYou from "../assets/images/cards/thank-you.jpg";
import GetWellSoon from "../assets/images/cards/get-well-soon.jpg";
import PhotoUpload from "../assets/images/cards/photo-upload.jpg";
import { useAppStore } from "../stores/AppStore";



export const MessageCardAnimation = () => {

	const { peakConfigs } = useAppStore(({ peakConfigs }) => {
		return { peakConfigs };
	});

	const tempImages = [
		{
			image: Birthday,
			text: "Birthday Cards"
		},
		{
			image: Sympathy,
			text: "Sympathy Cards"
		},
		{
			image: ThankYou,
			text: "Thank You Cards"
		},
		{
			image: GetWellSoon,
			text: "Get Well Soon Cards"
		},
		{
			image: PhotoUpload,
			text: "Photo Upload Cards"
		}
	];


	if(peakConfigs?.MOTHERS_DAY) {
		tempImages.splice(0, 1, {
			image: MothersDay,
			text: "Mother's Day Cards"
		})
	}
	
	const images = tempImages;


	return (
		<div className="message-card-images">
			<div className="images-container">
				{images.map((image, i) => {
					return (
						<div
							key={i}
							className="image-container"
						>
							<img
								src={image.image}
								alt="Message Card"
							/>
							<span>{image.text}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};
