import { useState, useEffect } from "react";

import ValentinesPralines from "../assets/images/addons/valentines-pralines-red.jpg";
import { useProductStore } from "../stores/ProductStore";
import { Image } from "./Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "../assets/scss/components/_addon-popup.scss";

export const AddonPopup = ({ addonId, resolvePromise, setShowAddonPopup }) => {
	const { availableAddons, SetAddons } = useProductStore(({ availableAddons, SetAddons }) => ({ availableAddons, SetAddons }));

	const FinishPopup = (selected = false) => {
		if (selected) SetAddons(addonId);
		ClosePopup();
	};

	const ClosePopup = () => {
		if (resolvePromise) resolvePromise(true);
		setShowAddonPopup(false);
	};

	const [addonInfo, setAddonInfo] = useState(null);

	useEffect(() => {
		if (availableAddons && Object.keys(availableAddons).length !== 0 && Object.keys(availableAddons).includes(addonId)) {
			setAddonInfo(availableAddons[addonId]);
		}
	}, [availableAddons, addonId]);

	return (
		addonInfo &&
		addonInfo?.pretty_name && (
			<div className="addon-popup-backdrop">
				<div className="addon-popup-modal">
					<div className="row justify-content-space-between align-items-stretch">
						<div className="col-12 col-md-6">
							<div className="image">
								<Image
									className="addon-popup-image"
									src={ValentinesPralines}
									alt="Valentine's Pralines"
									width="400"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="text">
								<h6>{addonInfo?.pretty_name ?? ""}</h6>

								<p>For just £{parseFloat(addonInfo?.price ?? "").toFixed(2)}</p>

								<button onClick={() => FinishPopup(true)}>Yes Please</button>

								<button
									className="no-thanks"
									onClick={() => FinishPopup(false)}
								>
									No Thanks
								</button>
							</div>
							<FontAwesomeIcon
								className="close-icon"
								icon={faClose}
								onClick={() => FinishPopup(false)}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	);
};
