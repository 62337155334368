import { ApiRequest } from "./base";

const apiRoute = "payment/klarna";

export const KlarnaApi = {
	async getClientToken() {
		let result = await ApiRequest(`${apiRoute}/initiate`, "POST");
		return result?.payment?.client_token;
	},
	async finalizeOrder(authToken) {
		let result = await ApiRequest(`${apiRoute}/finalize`,
			"POST",
			{
				authorization_token: authToken
			}
		);
		return result;
	}
};
