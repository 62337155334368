import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import { MiniCartItem } from ".";

import "../assets/scss/components/_mini-cart.scss";
import { useCartStore } from "../stores/CartStore";

import { Link } from "react-router-dom";
import { useUserStore } from "../stores/UserStore";
import { PaymentOptions } from "./PaymentOptions";

export const MiniCart = ({ status = false, cart = {} }) => {
	const { ClearCart, totals } = useCartStore(({ ClearCart, totals }) => ({ ClearCart, totals }));

	const { userInfo } = useUserStore(({ userInfo }) => {
		return { userInfo };
	});

	const location = useLocation();

	const [paymentFormShown, setPaymentFormShown] = useState(false);

	useEffect(() => {
		setPaymentFormShown(false);
	}, [location, cart]);

	return (
		<div className={`mini-cart basket-container ${status ? "active" : ""}`}>
			<div className="basket-container-wrapper">
				<div className="basket-container-subtitle row align-items-center">
					<div className="col-fluid">
						<strong>Cart ({Object.keys(cart).length})</strong>
					</div>
					{Object.keys(cart).length > 0 && !paymentFormShown ? (
						<button
							className="col-fluid remove-all-button no-style"
							onClick={() => {
								ClearCart();
							}}
						>
							<FontAwesomeIcon icon={faTrashCan} /> Remove All
						</button>
					) : (
						<strong>
							Total Price: <span>£{totals.grandTotal && totals.grandTotal.toFixed(2)}</span>
						</strong>
					)}
				</div>
				{!paymentFormShown && (
					<div className="basket-items">
						{Object.keys(cart).length === 0 ? (
							<p className="empty-cart">Your cart is empty, go do some shopping!</p>
						) : (
							Object.keys(cart).map((index) => {
								if (!cart[index].product_info) {
									return "";
								}

								if (cart[index].addons && Array.isArray(cart[index].addons) === false) {
									cart[index].addons = Object.values(cart[index].addons);
								}

								return (
									<MiniCartItem
										key={index}
										image={cart[index].product_info.image ?? ""}
										productName={cart[index].product_info.products_name ?? ""}
										price={cart[index].product_info.totals.grand_total ?? ""}
										size={cart[index].size ?? ""}
										deliveryDate={cart[index].deliverydate ?? ""}
										addons={cart[index].addons ?? {}}
										cartKey={index}
										messageCard={cart[index].messagecard ?? ""}
										personalisedCardID={cart[index].personalised ?? ""}
										deliveryAddress={`${cart[index].recipient_info.name} at ${cart[index].recipient_info.line1}, ${cart[index].recipient_info.town}, ${cart[index].recipient_info.postcode}`}
										videoMessage={cart[index].videomsg ?? false}
										editButtonShown={false}
									/>
								);
							})
						)}
						{}
					</div>
				)}
				{Object.keys(cart).length > 0 && (
					<div className="basket-continue">
						{!paymentFormShown && (
							<div className="total-price">
								<h3>
									Total Price: <span>£{totals.grandTotal && totals.grandTotal.toFixed(2)}</span>
								</h3>
							</div>
						)}

						{Object.keys(cart).length !== 0 && !paymentFormShown && (
							<button
								className="buy-now-button emphasis-button"
								onClick={() => {
									setPaymentFormShown(true);
								}}
							>
								Buy Now
							</button>
						)}

						<div className="payment-form">
							{paymentFormShown === true && (
								<PaymentOptions
									cart={cart}
									userInfo={userInfo}
									totals={totals}
								/>
							)}
						</div>
						<Link
							to="/cart"
							className="btn cart-btn"
						>
							View Your Cart
						</Link>
					</div>
				)}
			</div>{" "}
		</div>
	);
};
