import React, { useEffect } from "react";
import { DateDifference } from "../utilities/DateUtils";
import anime from "animejs";
import { Link } from "react-router-dom";

import "../assets/scss/components/_peak-label-animation.scss";

export const PeakLabelAnimation = ({ text = "", date = false, link = "/next-day-flowers" }) => {
	useEffect(() => {
		anime.timeline({ loop: false }).add({
			targets: ".peak-label-letters .peak-label-letter",
			translateY: ["1.5em", 0],
			translateZ: 0,
			duration: 750,
			delay: (el, i) => 50 * i
		});
	}, []);

	if (text === "" || !date) return null;

	const daysLeft = date ? DateDifference(date).days + 1 : null;

	let dateSpecificText = "";
	dateSpecificText = daysLeft ? `${daysLeft} days until ` : "";
	dateSpecificText = daysLeft === 1 ? "Last chance to order for " : dateSpecificText;

	if (daysLeft < 0) return null;

	return (
		<Link
			to={link}
			className="peak-label-animation"
		>
			<span className="peak-label-text-wrapper">
				<span className="peak-label-letters">
					{dateSpecificText.split("").map((char, index) => {
						return (
							<span
								key={index}
								className={`peak-label-letter date-specific ${char == " " ? "space" : ""}`}
							>
								{char !== " " ? char : "/"}
							</span>
						);
					})}

					{text.split("").map((char, index) => {
						return (
							<span
								key={index}
								className={`peak-label-letter ${char == " " ? "space" : ""}`}
							>
								{char !== " " ? char : "/"}
							</span>
						);
					})}
				</span>
			</span>
		</Link>
	);
};
