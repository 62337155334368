import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import LoadingImage from "../assets/images/bunch-of-flowers.gif";

import "../assets/scss/components/_loading-animation.scss";

import { Image } from "./Image";

export const LoadingAnimation = ({ link = true, linkOverride = "/", buttonText = "Back To Home", bodyOverride, titleOverride, imageOverride, contained = false, classes = "", titleTimer = 3, bodyTimer = 5, buttonTimer = 6, textContent = true, loadedCondition = true }) => {
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		let seconds = 0;
		const interval = setInterval(() => {
			seconds++;
			if (seconds === buttonTimer) {
				clearInterval(interval);
				console.error("POTENTIAL ERROR: LoadingAnimation timeout reached.");
			}
			setSeconds(seconds);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<section className={`loading-animation ${contained === true ? "contained" : ""} ${classes}`}>
			<div className="row align-content-center justify-content-center">
				<div className="loading-animation-content">
					<div className="loading-animation-image">
						<Image
							src={imageOverride ?? LoadingImage}
							alt="Loading animation"
						/>
					</div>

					{textContent && (
						<div className={`timeout-message ${titleTimer === 0 || seconds >= titleTimer ? "active" : "not-active"}`}>
							<h3>{titleOverride ?? "Sorry, this is taking longer than expected."}</h3>
							<p className={`${bodyTimer === 0 || seconds >= bodyTimer ? "active" : "not-active"}`}>{bodyOverride ?? "It looks like we're having trouble loading this page, please try again later or try a different page."}</p>

							{link && (
								<Link to={linkOverride}>
									<button className={`button ${buttonTimer === 0 || seconds >= buttonTimer ? "active" : "not-active"}`}>{buttonText}</button>
								</Link>
							)}
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
