import React, { useState } from "react";

import SVG from "react-inlinesvg";

import KlarnaIcon from "../assets/images/klarna-full.svg";

import "../assets/scss/components/_klarna-homepage-banner.scss";

import Config from "../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

export const KlarnaHomepageBanner = ({ setUspAutoplay }) => {
	const [klarnaScriptLoaded, setKlarnaScriptLoaded] = useState(typeof window?.Klarna !== "undefined");
	const [klarnaInfoVisible, setKlarnaInfoVisible] = useState(false);
	const HandleButtonPress = () => {
		if (!klarnaScriptLoaded) {
			const script = document.createElement("script");
			script.src = "https://js.klarna.com/web-sdk/v1/klarna.js";
			script.async = true;
			script.dataset.clientId = Config.KLARNA_SDK_KEY;
			script.dataset.environment = Config.KLARNA_ENV;
			script.onload = () => {
				setKlarnaScriptLoaded(true);
			};
			document.body.appendChild(script);
		}

		setKlarnaInfoVisible(true);

		setUspAutoplay(false);
	};

	return (
		<div className="klarna-homepage-banner">
			Shop now, pay later with Klarna.{" "}
			<button
				className="learn-more no-style"
				onClick={() => {
					HandleButtonPress();
				}}
			>
				Learn More
			</button>
			<div className="logo-wrapper">
				<SVG src={KlarnaIcon} />
			</div>
			{klarnaInfoVisible && (
				<div className="klarna-info">
					<button
						className="close-button no-style"
						onClick={() => {
							setKlarnaInfoVisible(false);
							setUspAutoplay(true);
						}}
					>
						<FontAwesomeIcon icon={faXmark} />
					</button>
					<klarna-placement
						data-key="info-page"
						data-locale="en-GB"
					></klarna-placement>
				</div>
			)}
		</div>
	);
};

export default KlarnaHomepageBanner;
