import React, { useEffect, useRef } from "react";
import { useCartStore } from "../stores/CartStore";
import { faBagShopping } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MiniCart } from "./MiniCart";

export const MiniCartContainer = () => {
	const miniCartRef = useRef(null);

	const { cart, GetCart, ToggleMiniCart, miniCart } = useCartStore(({ cart, GetCart, ToggleMiniCart, miniCart }) => ({
		cart,
		GetCart,
		ToggleMiniCart,
		miniCart
	}));

	useEffect(() => {
		GetCart();
	}, []);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (!miniCartRef.current) return;
			if (miniCart && !miniCartRef.current.contains(e.target)) {
				ToggleMiniCart(false);
			}
		};

		document.addEventListener("click", handleOutsideClick, true);
		return () => {
			document.removeEventListener("click", handleOutsideClick, true);
		};
	}, [miniCart]);

	return (
		!location.pathname.includes("/cart") && (
			<div
				className="basket-link mini-cart-container no-style"
				ref={miniCartRef}
				style={{
					fontWeight: "bold",
					fontSize: "20px"
				}}
			>
				<div
					className="basket-link-icon"
					onClick={() => ToggleMiniCart()}
					style={{
						textAlign: "center",
						cursor: "pointer"
					}}
				>
					{cart?.length > 0 && <div className="cart-length">{cart.length}</div>}
					<FontAwesomeIcon icon={faBagShopping} />
				</div>
				<span
					style={{
						display: "block",
						fontSize: "13px",
						cursor: "pointer"
					}}
					onClick={() => ToggleMiniCart()}
				>
					Basket
				</span>
				<div className="mini-cart-wrapper">
					{cart && (
						<MiniCart
							status={miniCart}
							cart={cart}
						/>
					)}
				</div>
			</div>
		)
	);
};
