import React, { useState, useEffect } from "react";

import Client from "getaddress-api";
import { useProductStore } from "../stores/ProductStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faMap } from "@fortawesome/pro-regular-svg-icons";

import { Map } from "./Map";
import { Spinner } from "./Spinner";
import Field from "./Field";

import "../assets/scss/components/_delivery-form-new.scss";

export const DeliveryForm = ({ showFullAddress = false, setShowFullAddress }) => {
	const [searchedPostcode, setSearchedPostcode] = React.useState("");

	const address_api = new Client("i8NauK2GE0Ot5_Dn2P5PcQ3331");

	const [lat, setLat] = React.useState(0);
	const [long, setLong] = React.useState(0);

	const [addresses, setAddresses] = React.useState([]);
	const [suggestedAddress, setSuggestedAddress] = React.useState(false);

	const [showAddressSelect, setShowAddressSelect] = React.useState(false);
	const [showSuggestedAddress, setShowSuggestedAddress] = React.useState(false);

	const [loadingList, setLoadingList] = React.useState(false);
	const [validAddress, setValidAddress] = React.useState(false);

	const { SetAddress, address } = useProductStore(({ SetAddress, address }) => {
		return { SetAddress, address };
	});

	const AddressAutocomplete = async (address) => {
		if (address.length >= 5) {
			try {
				const addressLookup = await address_api.find(address);
				if (addressLookup.isSuccess) {
					const success = addressLookup.toSuccess();
					if (success.addresses.addresses.length > 0) {
						return success.addresses;
					}
				}
			} catch (error) {
				return false;
			}
		}
	};

	useEffect(() => {
		const postcode = address.address_postcode ? address.address_postcode : false;
		const houseNumber = address.address_line1 ? address.address_line1.toLowerCase() : false;

		if (!postcode) return;

		const NoAddresses = () => {
			setValidAddress(false);
			setLoadingList(false);
		};

		const HandleAddressSuccess = (address_arr) => {
			if (!address_arr || !address_arr.length === 0) return;

			setAddresses(address_arr);

			const FoundAddress = (address) => {
				setSuggestedAddress(address);
				return true;
			};

			address_arr.forEach((address, key) => {
				if (houseNumber !== "" && (address.building_number === houseNumber || address.line_1 === houseNumber)) {
					return FoundAddress(address);
				}

				if (houseNumber.length >= 3) {
					if (address.building_number.substring(0, houseNumber.length).toLowerCase() === houseNumber || address.line_1.substring(0, houseNumber.length).toLowerCase() === houseNumber) {
						return FoundAddress(address);
					}
				}
			});

			setLoadingList(false);
		};

		setSuggestedAddress(false);
		setValidAddress(true);

		if (postcode !== searchedPostcode) {
			setLoadingList(true);
		}

		if (postcode !== searchedPostcode) {
			AddressAutocomplete(postcode).then((data) => {
				if (!data || !data?.addresses.length === 0) return NoAddresses();
				HandleAddressSuccess(data.addresses);
				setLat(data.latitude);
				setLong(data.longitude);
			});
		} else {
			if (!addresses || !addresses.length === 0) return NoAddresses();
			HandleAddressSuccess(addresses);
		}

		setSearchedPostcode(postcode);
	}, [address.address_postcode, address.address_line1]);

	useEffect(() => {
		if (address?.address_line1 !== suggestedAddress?.line_1) setShowSuggestedAddress(true);
	}, [address.address_line1]);

	return (
		<div className="delivery-form-section">
			{" "}
			<div className="row">
				<div className="col-12 col-md-2">
					<Map
						lat={lat}
						long={long}
						height={150}
					/>
				</div>
				<div className="col-12 col-md-10">
					<Field
						label="Recipient Name"
						value={address.address_name ? address.address_name : ""}
						required={true}
						classes="name-field"
						minLength={1}
						autocomplete="name"
						onChange={(e) => {
							SetAddress({ address_name: e.target.value });
						}}
					/>

					<div className="row align-items-center justify-content-space-between">
						<Field
							label="Postcode"
							value={address?.address_postcode ? address.address_postcode : ""}
							required={true}
							classes={`col-12 col-md-6 postcode-field`}
							minLength={5}
							autocomplete="postal-code"
							onChange={(e) => {
								e.target.value = e.target.value.toUpperCase();
								SetAddress({ address_postcode: e.target.value });
							}}
						/>
						<Field
							label="Line 1"
							value={address.address_line1 ? address.address_line1 : ""}
							required={true}
							classes="col-12 col-md-6 line1-field"
							autocomplete="address-line1"
							minLength={1}
							onChange={(e) => {
								SetAddress({ address_line1: e.target.value });
								if (window.innerWidth < 768) {
									setShowFullAddress(true);
								}
							}}
						/>
					</div>

					{!showFullAddress && (
						<div
							className="col-12 show-manually"
							onClick={() => {
								setShowFullAddress(true);
							}}
						>
							Not found your address? Click here to enter it manually.
						</div>
					)}

					<div className="addresses">
						<div className="row justify-content-space-between">
							<div className="col-12">
								{suggestedAddress && showSuggestedAddress && (
									<div className="suggested-address">
										<strong className="suggested-address-title">
											<FontAwesomeIcon icon={faMap} />
											Do you mean
										</strong>
										<span className="first-line">{suggestedAddress.line_1}</span>
										<span>{suggestedAddress.line_2}</span>
										<span>
											{suggestedAddress.town_or_city}, {suggestedAddress.county} {address?.postcode?.toUpperCase()}
										</span>

										<div className="row justify-content-space-between">
											<div className="col-12 col-md-6">
												<div
													className="btn correct"
													onClick={() => {
														SetAddress({
															address_line1: suggestedAddress.line_1,
															address_line2: suggestedAddress.line_2,
															address_town: suggestedAddress.town_or_city,
															address_county: suggestedAddress.county,
															address_country: suggestedAddress.country
														});
														setShowFullAddress(true);

														setShowSuggestedAddress(false);
													}}
												>
													Select This Address
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div
													className="btn not-correct"
													onClick={() => {
														setShowSuggestedAddress(false);
														setShowAddressSelect(true);
													}}
												>
													No, select my address
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							{showAddressSelect && (
								<div className="col-12">
									<div className="address-select-container">
										<strong className="address-select-title">
											<FontAwesomeIcon icon={faMagnifyingGlass} />
											Select your address
										</strong>
										{loadingList ? (
											<Spinner />
										) : validAddress ? (
											<div className="address-select">
												{addresses.length > 0 &&
													addresses.map((address, key) => {
														return (
															<div
																key={key}
																className="address"
																onClick={() => {
																	SetAddress({
																		address_line1: address.line_1,
																		address_line2: address.line_2,
																		address_town: address.town_or_city,
																		address_county: address.county,
																		address_country: address.country
																	});
																	setSuggestedAddress(address);
																	setShowAddressSelect(false);
																	setShowSuggestedAddress(false);
																	setShowFullAddress(true);
																}}
															>
																<span className="first-line">{address.line_1}</span>
															</div>
														);
													})}
											</div>
										) : (
											<div className="no-addresses">
												<FontAwesomeIcon icon={faMagnifyingGlass} />
												No addresses found for this postcode
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>

					<div className={`${showFullAddress ? "shown" : ""} full-address row align-items-center justify-content-space-between`}>
						<Field
							label="Line 2"
							value={address.address_line2 ? address.address_line2 : ""}
							classes="col-12 col-md-6 line2-field"
							autocomplete="address-line2"
							minLength={1}
							onChange={(e) => {
								SetAddress({ address_line2: e.target.value });
							}}
						/>

						<Field
							label="City/Town"
							value={address.address_town ? address.address_town : ""}
							required={true}
							classes="col-12 col-md-6 town-field"
							autocomplete="address-level2"
							minLength={1}
							onChange={(e) => {
								SetAddress({ address_town: e.target.value });
							}}
						/>

						<Field
							label="County"
							value={address.address_county ? address.address_county : ""}
							required={true}
							classes="col-6 county-field"
							autocomplete="address-level1"
							minLength={1}
							onChange={(e) => {
								SetAddress({ address_county: e.target.value });
							}}
						/>

						<Field
							label="Country"
							value={address.address_country ? address.address_country : ""}
							required={true}
							classes="col-6 country-field"
							autocomplete="country"
							minLength={1}
							onChange={(e) => {
								SetAddress({ address_country: e.target.value });
							}}
						/>

						<div className="phone-container col-12">
							<strong>Want to Receive SMS Updates?</strong>
							<p>Enter your phone number below and we will send you SMS tracking updates on your gift where available.</p>
							<hr />
							<Field
								label="Phone"
								value={address.address_phone ? address.address_phone : ""}
								// required={true}
								classes="col-12 col-md-6 phone-field"
								autocomplete="tel"
								minLength={1}
								onChange={(e) => {
									SetAddress({ address_phone: e.target.value });
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeliveryForm;
