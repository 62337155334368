import React, { useState, useEffect, useRef } from "react";

import dropin from "braintree-web-drop-in";

import { ApiRequest } from "../api/base";

import Config from "../Config";

import { useNavigate } from "react-router-dom";

import "../assets/scss/components/_braintree-dropin.scss";
import { LoadingAnimation } from "./LoadingAnimation";

import loading from "../assets/images/package.gif";

import { useCartStore } from "../stores/CartStore";
import { useUserStore } from "../stores/UserStore";
import { useAppStore } from "../stores/AppStore";

export const BraintreeDropin = (props) => {
	const navigate = useNavigate();
	const braintreeDropin = useRef(null);

	const { totals } = useCartStore(({ totals }) => {
		return { totals };
	});

	const { userInfo } = useUserStore(({ userInfo }) => {
		return { userInfo };
	});

	const { cart } = useCartStore(({ cart }) => {
		return { cart };
	});

	const hasSubscription = cart.some((item) => Config.recurringPaymentOptions.includes(item.size));

	useEffect(() => {
		if (!(userInfo && userInfo.id)) {
			navigate("/login?redirect=cart");
		} else {
			let fields = {};

			if (userInfo.billing_information && userInfo.billing_information.line_1) {
				fields.line_1 = userInfo.billing_information.line_1;
			}
			if (userInfo.billing_information && userInfo.billing_information.postcode) {
				fields.postcode = userInfo.billing_information.postcode;
			}
			if (userInfo.billing_information && userInfo.billing_information.phone_number) {
				fields.phone_number = userInfo.billing_information.phone_number;
			}
			if (userInfo.billing_information && userInfo.billing_information.name) {
				fields.name = userInfo.billing_information.name;
			}

			setBillingInformation(fields);
		}
	}, [userInfo, navigate]);

	if (useAppStore.getState().isDebug) {
		console.log(`BraintreeDropin.jsx - Outputting totals`, totals);
	}

	const { paymentMethodsEnabled } = Config;

	const [billingInformation, setBillingInformation] = useState({
		name: "",
		phone_number: "",
		line_1: "",
		postcode: ""
	});

	const [submitButtonStatus, setSubmitButtonStatus] = useState(false); //confirm payment method
	const [confirmButtonStatus, setConfirmButtonStatus] = useState(false); //pay button
	const [paymentProcessing, setPaymentProcessing] = useState(false);

	const [braintreeInstance, setBraintreeInstance] = useState(undefined);
	const [loadedInstance, setLoadedInstance] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(false);

	const onPaymentCompleted = () => {
		if (useAppStore.getState().isDebug) {
			console.log(`BraintreeDropin.jsx - Payment success`);
		}

		navigate("/cart/payment-success");
	};

	const applePayEnabled = paymentMethodsEnabled.applePayEnabled;
	const googlePayEnabled = paymentMethodsEnabled.googlePayEnabled;

	useEffect(() => {
		if (useAppStore.getState().isDebug) {
			console.log(`BraintreeDropin.jsx - Submit button status`, submitButtonStatus);
			console.log(`BraintreeDropin.jsx - Confirm button status`, confirmButtonStatus);
		}
	}, [confirmButtonStatus, submitButtonStatus]);

	const errorHandler = (error) => {
		console.error(error && error.message ? error.message : "Sorry, something went wrong. Please try again or try another payment method.");
	};

	const isInitialised = (dropInstance) => {
		setBraintreeInstance(dropInstance);

		dropInstance.on("paymentMethodRequestable", function (event) {
			if (useAppStore.getState().isDebug) {
				console.log(`BraintreeDropin.jsx - Payment method requestable`);
			}

			setSubmitButtonStatus(true);
		});
		dropInstance.on("noPaymentMethodRequestable", function (event) {
			if (useAppStore.getState().isDebug) {
				console.log(`BraintreeDropin.jsx - No payment method requestable`, totals);
			}

			setSubmitButtonStatus(false);
		});

		// Check on initialization if there's a payment method already selected (vaulted)
		if (dropInstance.isPaymentMethodRequestable()) {
			setSubmitButtonStatus(true);
		}

		return dropInstance;
	};

	const sendPaymentRequest = (instance) => {
		setConfirmButtonStatus(false);
		setPaymentProcessing(true);
		return ApiRequest("cart/finalise", "POST").then((response) => {
			if (useAppStore.getState().isDebug) {
				console.log(`BraintreeDropin.jsx - Finalise response`, response);
			}

			if (response.payment.success === true) {
				//payment is good
				onPaymentCompleted();
			} else {
				console.error(response.message + " - " + response.error);
				return instance.clearSelectedPaymentMethod();
			}
		});
	};

	const initialiseBraintree = (client_token) => {
		let options = {
			authorization: client_token,
			// container: "#braintree-container",
			container: braintreeDropin.current,
			card: {
				overrides: {
					fields: {
						postalCode: {
							prefill: billingInformation.postcode
						}
					}
				},
				vault: {
					allowVaultCardOverride: hasSubscription ? false : true,
					vaultCard: hasSubscription ? true : false
				}
			},
			threeDSecure: true,
			dataCollector: {
				kount: false // Required if Kount fraud data collection is enabled
			},
			vaultManager: hasSubscription ? true : false,
			paypal: false
		};

		let googlePay = {
			googlePay: {
				googlePayVersion: 2,
				merchantId: "",
				transactionInfo: {
					totalPriceStatus: "FINAL",
					totalPrice: totals.grandTotal,
					currencyCode: "GBP",
					countryCode: "GB"
				},
				merchantInfo: {
					merchantName: "123 Flowers",
					merchantId: ""
				}
			}
		};

		let applePay = {
			applePay: {
				displayName: "123 Flowers",
				paymentRequest: {
					total: {
						label: "123 Flowers",
						amount: totals.grandTotal
					},
					countryCode: "GB",
					currencyCode: "GBP"
				}
			}
		};

		if (googlePayEnabled) {
			options = { ...options, ...googlePay };
		}

		if (applePayEnabled) {
			options = { ...options, ...applePay };
		}

		dropin.create(options).then((instance) => {
			isInitialised(instance);
			// setSubmitButtonStatus(true);
			setLoadedInstance(true);
			if (useAppStore.getState().isDebug) {
				console.log(`BraintreeDropin.jsx - Dropin loaded`);
			}
		});
	};

	const generateClientToken = () => {
		let vaultCustomer = true;
		let datas = new FormData();
		datas.append("vault_customer", vaultCustomer);

		return ApiRequest("payment/braintree/get-token", "POST", {
			vault_customer: vaultCustomer
		}).then((response) => {
			return response.payment.client_token;
		});
	};

	const confirmPaymentMethod = (instance) => {
		if (useAppStore.getState().isDebug) {
			console.log(`BraintreeDropin.jsx - Confirm payment method`, instance);
		}

		let confirmationInfo = {
			amount: totals.grandTotal,
			email: userInfo.email,
			billingAddress: {
				givenName: billingInformation.name,
				phoneNumber: billingInformation.phone_number,
				streetAddress: billingInformation.line_1,
				extendedAddress: "",
				postalCode: billingInformation.postcode
			},
			collectDeviceData: true
		};

		return instance
			.requestPaymentMethod({
				threeDSecure: confirmationInfo
			})
			.then((payload) => {
				console.log(payload);

				// payload.type && setPaymentMethod(payload.type);

				return ApiRequest("payment/braintree/save-nonce", "POST", {
					nonce: payload.nonce,
					device_data: payload.deviceData
				}).then((response) => {
					if (useAppStore.getState().isDebug) {
						console.log(`BraintreeDropin.jsx - Nonce saved`, response);
					}

					//NOTHING RETURNING
					// if (response.success) {
					// 	setSubmitButtonStatus(false);
					// 	setConfirmButtonStatus(true);
					// } else {
					// 	console.log("NO RESPONSE SUCCESS");
					// }
					setSubmitButtonStatus(false);
					// setConfirmButtonStatus(true);

					ProcessPayment();
				});
			})
			.catch(errorHandler);
	};

	const ProcessPayment = () => {
		braintreeInstance.requestPaymentMethod((error, payload) => {
			if (error) {
				console.error("payment request error", error);
			} else {
				if (useAppStore.getState().isDebug) {
					console.log(`BraintreeDropin.jsx - Send payment request`);
				}

				sendPaymentRequest(braintreeInstance);
			}
		});
	};

	useEffect(() => {
		if (!braintreeDropin.current) {
			return;
		}
		generateClientToken().then((token) => {
			if (useAppStore.getState().isDebug) {
				console.log(`BraintreeDropin.jsx - Dropin token generated`, token);
			}

			if (braintreeInstance) {
				braintreeInstance.teardown().then(() => {
					initialiseBraintree(token);
				});
			} else {
				initialiseBraintree(token);
			}
		});
	}, []);
	// }, [braintreeDropin.current]);

	if (useAppStore.getState().isDebug) {
		console.log(`BraintreeDropin.jsx - Loading instance status`, loadedInstance);
	}

	useEffect(() => {
		if (useAppStore.getState().isDebug) {
			console.log(`BraintreeDropin.jsx - Cart has changed, refreshing`, cart);
		}
	}, [cart]);

	return (
		<div className="braintree-dropin">
			<div
				id="braintree-container"
				ref={braintreeDropin}
			/>

			{!loadedInstance && (
				<LoadingAnimation
					titleOverride={"We're just packing your order"}
					bodyOverride={"It looks like we're having some issues loading the payment form! Please reload and if the problem persists try again later."}
					imageOverride={loading}
					titleTimer={0}
					contained={props.containedLoading ? true : false}
					bodyTimer={5}
				/>
			)}

			{submitButtonStatus && (
				<button
					className="braintreePayButton btn emphasis-button"
					disabled={!braintreeInstance}
					onClick={() => {
						if (braintreeInstance) {
							confirmPaymentMethod(braintreeInstance);
						}
					}}
				>
					Buy Now
				</button>
			)}

			{confirmButtonStatus && (
				<button
					className="braintreeSubmitButton btn emphasis-button"
					disabled={!confirmButtonStatus}
					onClick={() => {
						ProcessPayment();
					}}
				>
					Pay with {paymentMethod}
				</button>
			)}

			{paymentProcessing && <p className="payment-processing">Your payment is processing now, please wait you will be redirected to the next page shortly.</p>}
		</div>
	);
};
