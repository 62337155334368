import React, { useEffect } from "react";

import { useCategoriesStore } from "../stores/CategoriesStore";

import { OutputProducts } from "./OutputProducts";
import { LoadingAnimation } from "./LoadingAnimation";
import { HomeCallout } from "./HomeCallout";

export const HomeProducts = () => {
	const { homepageInfo, GetHomepage } = useCategoriesStore(({ homepageInfo, GetHomepage }) => ({ homepageInfo, GetHomepage }));

	useEffect(() => {
		GetHomepage();
	}, [GetHomepage]);

	return (
		<div className="home-products">
			{homepageInfo.length ? (
				<>
					<div className="featured">
						<div className="container">
							<OutputProducts
								products={homepageInfo[0]}
								loadingAnimation={false}
								lazy={true}
								aboveFold={true}
								buyButton={true}
								description={window.innerWidth > 769 ? true : false}
							/>
						</div>
					</div>

					<HomeCallout />

					<div className="remaining">
						<div className="container">
							<OutputProducts
								products={homepageInfo[1]}
								loadingAnimation={false}
								lazy={true}
								buyButton={true}
								description={window.innerWidth > 769 ? true : false}
							/>
						</div>
					</div>
				</>
			) : (
				<LoadingAnimation />
			)}
		</div>
	);
};
