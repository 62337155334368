import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { Link } from "react-router-dom";

import HeaderImg from "../assets/images/lifestyles/123f-post-valentines-2025.jpg?w=1440";
import ValentinesHeaderImg from "../assets/images/lifestyles/millie-luke.png?w=1440";
import XmasHeaderImg from "../assets/images/lifestyles/2024-xmas-flowers-lifestyle-banner.jpg?w=1440";

import "../assets/scss/components/_home-upsell.scss";

import { Image, Slider } from ".";
import { useAppStore } from "../stores/AppStore";
import { Helmet } from "react-helmet";

export const HomeUpsell = () => {
	const { peakConfigs } = useAppStore(({ peakConfigs }) => {
		return { peakConfigs };
	});

	let headerLink = "/spring-flowers";
	let headerText = "Seasonal Flowers";
	let headerDesc = "Enjoy beautiful seasonal bouquets perfect for every occasion including Birthdays, Anniversaries and more! Including beautiful seasonal Tulips lovingly handtied by expert florists!";
	let headerImage = HeaderImg;

	if (peakConfigs?.VALENTINES) {
		headerLink = "/valentines-flowers";
		headerText = "Valentines Bouquets";
		headerDesc = "Send the love this Valentine's Day with a beautiful bouquet of stunning Red Roses beautifully handtied by expert florist to make their Valentine's extra special!";
		headerImage = ValentinesHeaderImg;
	}

	if(peakConfigs?.MOTHERS_DAY) {
		headerLink = "/mothers-day-flowers";
		headerText = "Mother's Day Flowers";
		headerDesc = "Spoil Mum this Mother's Day with a beautiful bouquet of fresh seasonal flowers from stunning Spring Tulips to elegant Belle Roses lovingly handtied by our Florists!";
		headerImage = HeaderImg;
	}

	if (peakConfigs?.CHRISTMAS) {
		headerLink = "/christmas-flowers";
		headerText = "Christmas Flowers";
		headerDesc = "Make this Christmas picture perfect with our beautiful range of Christmas Bouquets! With seasonal colours and stems including deep reds and stunning greens!";
		headerImage = XmasHeaderImg;
	}

	return (
		<>
			<Helmet>
				<link
					rel="preload"
					as="image"
					href={headerImage}
				/>
			</Helmet>

			<section
				className="home-upsell"
				fetchpriority="high"
			>
				<div
					className="biggest-trends"
					style={
						{
							// backgroundImage: `url(${HeaderImg})`,
							// backgroundSize: "55%"
						}
					}
				>
					<div className="container">
						<div className="row justify-content-lg-space-between">
							<div className="col-12 col-md-6">
								<div className="upsell-img">
									<Image
										src={headerImage}
										alt={headerText}
										imported={true}
									/>
								</div>
							</div>
							<div className="col-md-5 trend-description">
								<strong className="upsell-title">{headerText}</strong>
								<p>{headerDesc}</p>
								<Link
									to={headerLink}
									className="btn minimal"
								>
									Shop Now
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
