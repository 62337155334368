import React, { useState, useEffect, useRef } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMagnifyingGlass, faClose, faGameConsoleHandheld } from "@fortawesome/pro-regular-svg-icons";

import "../assets/scss/components/_search-bar.scss";

import { SearchApi } from "../api";

import Config from "../Config";

import { Image } from "./Image";
import { Link } from "react-router-dom";

export const SearchBar = ({ instantSearch = true, mobile = false }) => {
	const inputRef = useRef(null);

	const navigate = useNavigate();
	const [searchBar, setSearchBar] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [searchFailed, setSearchFailed] = useState(false);
	const [products, setProducts] = useState([]);

	const location = useLocation();

	useEffect(() => {
		setSearchBar(false);
		setSearchValue("");
		setSearchFailed(false);
		setProducts([]);
		if (inputRef.current) {
			inputRef.current.value = "";
		}
	}, [location]);

	const getSearchProducts = async (searchValue) => {
		if (!searchValue) {
			return;
		}
		try {
			await SearchApi.InstantSearch(searchValue).then(({ search_results }) => {
				if (search_results && Object.keys(search_results).length > 0) {
					setProducts(search_results);
					setSearchFailed(false);
				} else {
					setProducts([]);
					setSearchFailed(true);
				}
			});
		} catch {
			console.log("err");
			setProducts([]);
			setSearchFailed(true);
		}
	};

	const handleButton = () => {
		if (searchValue !== "") {
			navigate(`/search/${searchValue}`);
		} else {
			setSearchBar((prev) => !prev);
		}
	};

	useEffect(() => {
		if (instantSearch) {
			const search = async () => {
				await getSearchProducts(searchValue);
			};

			search();
		}
	}, [searchValue]);

	return (
		<div
			className={`search-bar-container col-4 col-md-4 ${searchBar ? "active" : ""} ${mobile ? "mobile" : ""}`}
			style={{
				marginRight: "auto",
				marginLeft: "auto"
			}}
		>
			<div
				className="field"
				style={{
					border: "none"
				}}
			>
				<input
					type="text"
					placeholder=""
					ref={inputRef}
					style={{ background: "#F4F4F4" }}
					onInput={(e) => {
						setSearchValue(e.target.value);
						if (e.target.value === "") {
							setProducts([]);
						}
					}}
					aria-label="Search For Products"
					onKeyUp={(e) => {
						if (e.key === "Enter") {
							handleButton();
						}
					}}
					onFocus={() => {
						setSearchBar(true);
						if (inputRef.current && inputRef.current.value === "") {
							setProducts([]);
						}
					}}
					onBlur={() => {
						setTimeout(() => {
							setSearchBar(false);
						}, 200);
					}}
				/>
				<label htmlFor="">Search For Products</label>
			</div>

			{/* {searchBar && ( */}
			<div className={`results-container ${searchBar ? "active" : ""}`}>
				{!searchFailed && products && Object.keys(products).length > 0 && (
					<div className="search-results">
						{Object.keys(products).map((product, index) => {
							return (
								<Link
									to={`${Config.baseUrl}/${products[product].uri}`}
									className="search-result row justify-content-space-between align-items-center"
									key={index}
								>
									<div className="col-2 image-container">
										<Image
											width={100}
											src={products[product].full_image_url}
											alt={products[product].products_name}
										/>
									</div>
									<div className="col-10 description-container">
										<h4>
											{products[product].products_name} ({Config.currency}
											{parseFloat(products[product].price_retail).toFixed(2)})
										</h4>
										{products[product].short_description && <p>{products[product].short_description}</p>}
									</div>
								</Link>
							);
						})}
					</div>
				)}
			</div>
			{/* )} */}

			{!instantSearch && (
				<div
					className={`search-button`}
					aria-label="Search"
					onClick={() => {
						handleButton();
					}}
				>
					<FontAwesomeIcon icon={searchBar ? (searchValue === "" ? faClose : faCheck) : faMagnifyingGlass} />
				</div>
			)}
		</div>
	);
};
