import React, { useState, useMemo, useEffect } from "react";

import { DateInfo } from "../utilities/DateUtils";

import { useProductStore } from "../stores/ProductStore";

import "../assets/scss/components/_date-selector.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";

import { useAppStore } from "../stores/AppStore";

import DatePicker from "react-datepicker";

import { DeliveryOptions } from "./DeliveryOptions";

export const DateSelector = () => {
	const [firstChange, setFirstChange] = useState(true);

	const [customDate, setCustomDate] = useState(false);
	const [usingCustomDate, setUsingCustomDate] = useState(false);
	const [datepickerShown, setDatePickerShown] = useState(false);

	const { selectedDeliveryDate, SetSelectedDeliveryDate, info } = useProductStore((state) => state);

	const { upcoming_dates, disabled_dates } = info?.delivery ?? { upcoming_dates: [], disabled_dates: [] };

	const { peakConfigs } = useAppStore(({ peakConfigs }) => {
		return { peakConfigs };
	});

	const overridePeakDates = () => {
		if (peakConfigs?.MOTHERS_DAY) {
			return "2025-03-30";
		}
		return false;
	};

	let nextDaysString = "";

	const generateDates = () => {
		const dates = [];

		for (let i = 0; i < datesToShow; i++) {
			dates.push(upcoming_dates[i]);
		}

		let nextDaysPeakOverride = false;

		if (peakDate) {
			if (upcoming_dates && upcoming_dates.includes(peakDate)) {
				const peakDateIndex = upcoming_dates.indexOf(peakDate);
				if (peakDateIndex > datesToShow) {
					//peak is enabled but the date would not show normally
					dates.pop();
					dates.push(peakDate);
					nextDaysPeakOverride = true;
				}
			}
		}

		nextDaysString = nextDays(nextDaysPeakOverride);
		return dates;
	};

	const nextDays = (peak_override = false) => {
		let nextDaysString = "";

		for (let i = 0; i < 2; i++) {
			if (upcoming_dates[i + (peak_override ? datesToShow - 1 : datesToShow)]) {
				const formatted = DateInfo(upcoming_dates[i + (peak_override ? datesToShow - 1 : datesToShow)], true);
				nextDaysString += `${formatted.pretty.short.day} ${formatted.pretty.short.date}${i === 0 ? ", " : ""}`;
			}
		}

		return nextDaysString;
	};

	const datesToShow = 8;
	const peakDate = overridePeakDates();
	const dates = generateDates();
	const [peakDateSelected, setPeakDateSelected] = useState(false);

	const ChangeDeliveryDate = (date) => {
		SetSelectedDeliveryDate(date);
		setPeakDateSelected(peakDate && peakDate === date ? true : false);
	};

	let selected = selectedDeliveryDate;
	let selectedFormatted = DateInfo(selected, false);

	const dateOptionsRef = React.createRef();

	const peakCalloutSelect = () => {
		ChangeDeliveryDate(peakDate);

		if (!dateOptionsRef.current) return;

		const className = `${DateInfo(peakDate, false).pretty.short.month}${DateInfo(peakDate, false).pretty.short.date}`;

		const peakDateSelect = dateOptionsRef.current.querySelector(`.${className}`);
		if (peakDateSelect) peakDateSelect.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
	};

	return (
		<section className="date-selector">
			{peakDate &&
				upcoming_dates &&
				upcoming_dates.includes(peakDate) &&
				!peakDateSelected &&
				(peakConfigs?.MOTHERS_DAY ? (
					<div
						className="looking-for-section"
						onClick={() => {
							peakCalloutSelect();
						}}
					>
						<strong>Wanting it for Mother's Day?</strong> Click here to get it delivered on March 30th
					</div>
				) : peakConfigs?.VALENTINES_DAY ? (
					<div
						className="looking-for-section"
						onClick={() => peakCalloutSelect()}
					>
						<strong>Wanting it for Valentine's Day?</strong> Click here to get it delivered on February 14th
					</div>
				) : null)}

			<div
				className="date-options"
				ref={dateOptionsRef}
			>
				{dates.map((date, i) => {
					const formatted = DateInfo(date, false);

					let callout = "Selected";
					callout = i == 0 && firstChange ? "Quickest" : callout;
					callout = `${formatted?.pretty?.short?.month}${formatted?.pretty?.short?.date}` == "Feb14" ? "Valentine's" : callout;
					callout = `${formatted?.pretty?.short?.month}${formatted?.pretty?.short?.date}` == "Dec25" ? "Christmas" : callout;
					callout = `${formatted?.pretty?.short?.month}${formatted?.pretty?.short?.date}` == "Mar30" ? "Mother's Day" : callout;

					return (
						<div
							key={i}
							className={`date-select ${new Date(selectedDeliveryDate).getTime() == new Date(date).getTime() ? "active" : ""} ${formatted.pretty.short.month}${formatted.pretty.short.date}`}
							onClick={() => {
								setFirstChange(false);
								ChangeDeliveryDate(date);
								setUsingCustomDate(false);
								setCustomDate(false);
							}}
						>
							<div className="check">
								<FontAwesomeIcon icon={faCheck} />
							</div>

							<div className="day">{formatted.pretty.short.day}</div>
							<div className="date">{formatted.pretty.short.date.length === 1 ? `0${formatted.pretty.short.date}` : formatted.pretty.short.date}</div>
							<div className="month">{formatted.pretty.short.month}</div>
							<div className="delivery-cost">Free Delivery</div>
							<div className="callout">{callout}</div>
						</div>
					);
				})}
			</div>

			{upcoming_dates.length > datesToShow && (
				<div
					className="show-datepicker"
					onClick={() => {
						setCustomDate(true);
						setDatePickerShown(!datepickerShown);
					}}
				>
					<FontAwesomeIcon icon={faCalendar} /> Order for {nextDaysString} or a later date
				</div>
			)}

			{customDate && (
				<div className="datepicker">
					{usingCustomDate && (
						<div className="datepicker-choice">
							<div className={`date-select ${usingCustomDate ? "active" : ""} ${selectedFormatted.pretty.short.month}${selectedFormatted.pretty.short.date}`}>
								<div className="check">
									<FontAwesomeIcon icon={faCheck} />
								</div>

								<div className="day">{selectedFormatted.pretty.short.day}</div>
								<div className="date">{selectedFormatted.pretty.short.date.length === 1 ? `0${selectedFormatted.pretty.short.date}` : selectedFormatted.pretty.short.date}</div>
								<div className="month">{selectedFormatted.pretty.short.month}</div>
								<div className="delivery-cost">Free Delivery</div>
								<div className="callout">Selected</div>
							</div>

							<div
								className="change-choice"
								onClick={() => {
									setDatePickerShown(true);
								}}
							>
								<FontAwesomeIcon icon={faCalendar} />
								<span className="change-text">Change Date</span>
							</div>
						</div>
					)}

					{datepickerShown && (
						<div className="datepicker-container">
							<DatePicker
								selected={selectedDeliveryDate}
								onChange={(date) => {
									setFirstChange(false);
									setUsingCustomDate(true);
									ChangeDeliveryDate(date);
									setDatePickerShown(false);
								}}
								includeDates={info.delivery.upcoming_dates.map((date) => new Date(date))}
								excludeDates={info.delivery.disabled_dates.map((date) => new Date(date))}
								inline
							/>
						</div>
					)}
				</div>
			)}

			<div className="delivery-options-container">
				<strong className="delivery-expected">
					Delivery expected on {selectedFormatted.pretty.long.date} {selectedFormatted.pretty.long.month}
				</strong>
				<span className="delivered-by">via Royal Mail</span>

				<div className="delivery-options-wrapper">
					<DeliveryOptions />
				</div>
			</div>
		</section>
	);
};

export default DateSelector;
