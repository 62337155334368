import React from "react";

import "../assets/scss/components/_footer.scss";

import { Link } from "react-router-dom";
import { Image } from "./Image";

import Config from "../Config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faTiktok, faPinterest } from "@fortawesome/free-brands-svg-icons";
import { Seo } from "./Seo";

const PaymentTypes = React.lazy(() => import("./PaymentTypes"));

window._klOnsite = window._klOnsite || [];

export const Footer = () => {
	const { siteName, logo, socials } = Config;

	return (
		<div className="footer">
			<div className="central-footer">
				<div className="container">
					<div className="row justify-content-space-between">
						<div className="col-12 col-md-6">
							<strong>Popular Categories</strong>

							<div className="links">
								<div className="row">
									<div className="col-12 col-md-6">
										<Link
											to="/next-day-flowers"
											aria-label="Next Day Flowers"
											title="Next Day Flowers"
										>
											Next Day Flowers
										</Link>
										<Link
											to="/birthday-flowers"
											aria-label="Birthday Flowers"
											title="Birthday Flowers"
										>
											Birthday Flowers
										</Link>
										<Link
											to="/anniversary-flowers"
											aria-label="Anniversary Flowers"
											title="Anniversary Flowers"
										>
											Anniversary Flowers
										</Link>
										<Link
											to="/get-well-soon-flowers"
											aria-label="Get Well Soon Flowers"
											title="Get Well Soon Flowers"
										>
											Get Well Soon Flowers
										</Link>
									</div>
									<div className="col-12 col-md-6">
										<Link
											to="/sympathy-flowers"
											aria-label="Sympathy Flowers"
											title="Sympathy Flowers"
										>
											Sympathy Flowers
										</Link>
										<Link
											to="/new-baby-flowers"
											aria-label="New Baby Flowers"
										>
											New Baby Flowers
										</Link>
										<Link
											to="/thank-you-flowers"
											aria-label="Thank You Flowers"
											title="Thank You Flowers"
										>
											Thank You Flowers
										</Link>
										{/* <Link to="/best-selling-flowers">Best Selling Flowers Flowers</Link> */}
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<strong>Company Links</strong>
							<Link
								to="/delivery-information"
								aria-label="Delivery Information"
								title="Delivery Information"
							>
								Delivery Information
							</Link>
							<Link
								to="/about-us"
								aria-label="About Us"
								title="About Us"
							>
								About Us
							</Link>
							<Link
								to="/contact-us"
								aria-label="Contact Us"
								title="Contact Us"
							>
								Contact Us
							</Link>
							<Link
								to="https://www.reviews.co.uk/company-reviews/store/123-flowers-co-uk"
								target="_blank"
								aria-label="Reviews"
								title="Reviews"
							>
								Reviews
							</Link>
						</div>
						<div className="col-12 col-md-2">
							<strong>Legal</strong>
							<Link
								to="/terms"
								aria-label="Terms & Condiitons"
								title="Terms & Conditions"
							>
								Terms & Conditions
							</Link>
							<Link
								to="/privacy-policy"
								aria-label="Privacy Policy"
								title="Privacy Policy"
							>
								Privacy Policy
							</Link>
						</div>

						<div className="col-12 col-md-2">
							<strong>Socials</strong>
							<div className="row">
								<Link
									to={socials.facebook}
									target="_blank"
									rel="noreferrer"
									aria-label="Facebook"
									title="Facebook"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faFacebook}
									/>
								</Link>
								<Link
									to={socials.instagram}
									target="_blank"
									rel="noreferrer"
									aria-label="Instagram"
									title="Instagram"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faInstagram}
									/>
								</Link>
								<Link
									to={socials.twitter}
									target="_blank"
									rel="noreferrer"
									aria-label="Twitter"
									title="Twitter"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faTwitter}
									/>
								</Link>
								<Link
									to={socials.tiktok}
									target="_blank"
									rel="noreferrer"
									aria-label="TikTok"
									title="TikTok"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faTiktok}
									/>
								</Link>
								<Link
									to={socials.pinterest}
									target="_blank"
									rel="noreferrer"
									aria-label="Pinterest"
									title="Pinterest"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faPinterest}
									/>
								</Link>
							</div>
							<div className="row">
								<button
									type="button"
									className="btn"
									onClick={() => {
										window._klOnsite.push(["openForm", "XfAYBi"]);
									}}
								>
									Sign Up To Special Offers
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="lower-footer">
				<div className="container">
					<hr />
					<div className="row align-items-center justify-content-space-between">
						<div className="col-12 col-md-1">
							<Image
								src={logo}
								alt={siteName}
								imported={true}
							/>
						</div>
						<div className="col-12 col-md-3 copy">
							&copy; {new Date().getFullYear()} {siteName}, All Rights Reserved
						</div>
					</div>
				</div>
			</div>

			<Seo />

			<React.Suspense fallback={null}>
				<PaymentTypes />
			</React.Suspense>
		</div>
	);
};
