import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faWarning } from "@fortawesome/pro-regular-svg-icons";

import "../assets/scss/components/_message-card-section-new.scss";

import { GenerateImageLink } from "./Image";
import { useProductStore } from "../stores/ProductStore";
import { useCartStore } from "../stores/CartStore";
import Config from "../Config";
import PersonalisedCardImage from "../assets/images/happy-bday-card.jpg";
import { RecordAMessageSection } from "./RecordAMessageSection";
import { DateDifference } from "../utilities/DateUtils";
import { MessageCardAnimation } from "./MessageCardAnimation";

export const MessageCardSectionNew = () => {
	const [charsLeft, setCharsLeft] = useState(200);
	const [isPersonalisedCardModalOpen, setIsPersonalisedCardModalOpen] = useState(false);
	const [messageCardSelected, setMessageCardSelected] = useState(false);
	const [openVideoMessage, setOpenVideoMessage] = useState(false);

	const { SetMessageCardText, SetMessageCardType, messageCardType, messageCardText, GetAddPersonalisedCardLink, SetPrintzwareId, printzwareId, SetPrice, selectedDeliveryDate, info, videoMsg } = useProductStore((state) => ({
		SetMessageCardText: state.SetMessageCardText,
		SetMessageCardType: state.SetMessageCardType,
		messageCardType: state.messageCardType,
		messageCardText: state.messageCardText,
		GetAddPersonalisedCardLink: state.GetAddPersonalisedCardLink,
		SetPrintzwareId: state.SetPrintzwareId,
		printzwareId: state.printzwareId,
		SetPrice: state.SetPrice,
		selectedDeliveryDate: state.selectedDeliveryDate,
		videoMsg: state.videoMsg,
		info: state.info
	}));

	const { GetCart } = useCartStore((state) => state);

	useEffect(() => {
		GetCart();
	}, [GetCart]);

	const ChangeCardType = (type, value = false) => {
		if (type === "standard") {
			SetMessageCardType("standard");
			setMessageCardSelected("standard");
			SetPrintzwareId(null);

			if (value !== false) {
				SetMessageCardText(value || "");
			}
		} else {
			SetMessageCardType("personalised");
			setMessageCardSelected("personalised");
			SetMessageCardText("");
			if (value) SetPrintzwareId(value);
		}

		setIsPersonalisedCardModalOpen(false);
		SetPrice();
	};

	let currentTime = new Date();
	let PersonalisedCardCheck = true;

	PersonalisedCardCheck = DateDifference(selectedDeliveryDate, currentTime).days === 0 && currentTime.getHours() >= Config.personalisedCardCutoff[currentTime.getDay()] ? false : PersonalisedCardCheck;
	PersonalisedCardCheck = info?.distributor !== "julian" ? false : PersonalisedCardCheck;
	PersonalisedCardCheck = !Config.personalisedCardEnabled ? false : PersonalisedCardCheck;

	const [personalisedCardLink, setPersonalisedCardLink] = useState(GetAddPersonalisedCardLink());

	return (
		<section className="message-card-section">
			<strong className="section-title">Let them know who to thank:</strong>
			<p>A gift is never complete without a personalised message. Select your style below and we will include it with your gift.</p>

			<div className="message-card-options">
				{/* Standard Free Message Card */}
				<div
					className={`message-card-option ${messageCardType === "standard" ? "active" : ""}`}
					onClick={() => ChangeCardType("standard")}
				>
					<div className="message-card-option-text">
						<strong>Free Message Card</strong>
						<p>All gifts include a free message card. Type your message, and we will print and send it with your item.</p>
					</div>
					<div className="message-check">
						<div className="checkmark">
							<FontAwesomeIcon icon={faCheck} />
						</div>
						<strong className="price">FREE</strong>
					</div>

					{messageCardType === "standard" && (
						<div className="col-12 free-message-card-choice">
							<strong>Free Message Card</strong>
							<p>Type your message in the field or choose a personalised option below.</p>
							<div className="field">
								<div className="textarea-container">
									<textarea
										id="card-message"
										maxLength="200"
										rows="2"
										value={messageCardText}
										onChange={(e) => {
											setCharsLeft(200 - e.target.value.length);
											ChangeCardType("standard", e.target.value);
										}}
										disabled={messageCardType === "personalised"}
									/>
									<label>Card Message:</label>
									<div className="remaining-characters-section">
										<span className="remaining-characters">{charsLeft}</span> Characters Remaining
									</div>
								</div>
							</div>
						</div>
					)}
				</div>

				{PersonalisedCardCheck && (
					<div className={`message-card-option ${messageCardType === "personalised" ? "active" : ""}`}>
						<div
							className="message-card-option-text"
							onClick={() => ChangeCardType("personalised")}
						>
							<strong>Personalised Greeting Card</strong>
							<p>High-quality customised A5 greeting card. Choose from one of our designs and then customise it for your recipient.</p>
						</div>
						<div
							className="message-check"
							onClick={() => ChangeCardType("personalised")}
						>
							<div className="checkmark">
								<FontAwesomeIcon icon={faCheck} />
							</div>
							<strong className="price">{info?.free_card ? "FREE" : `+£${Config.personalisedCardPrice}`}</strong>
						</div>

						{messageCardType === "personalised" && (
							<div className="col-12">
								<div className="personalised-card-choice">
									<div
										className="text-content"
										onClick={() => setIsPersonalisedCardModalOpen(true)}
									>
										<strong>Get A Personalised Greeting Card</strong>
										{!printzwareId ? (
											<MessageCardAnimation />
										) : (
											<div
												className="personalised-card-cover card-added"
												style={{ backgroundImage: `url('${Config.personalisedThumbUrl}/${printzwareId}.jpg')` }}
												onClick={() => setIsPersonalisedCardModalOpen(true)}
											></div>
										)}
										<button
											className="get-started"
											onClick={() => setIsPersonalisedCardModalOpen(true)}
										>
											{!printzwareId ? "Get Started" : "Edit Card"}
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				)}

				{((messageCardSelected === "personalised" && printzwareId) || (messageCardSelected === "standard" && messageCardText !== "")) && (
					<>
						<strong className="also-add"> You can also add: </strong>

						<div className={`message-card-option ${videoMsg || openVideoMessage ? "active" : ""}`}>
							<div
								className="message-card-option-text"
								onClick={() => {
									setOpenVideoMessage(!openVideoMessage);
								}}
							>
								<strong>
									Video / Voice Message <span>New!</span>
								</strong>
								<p>Record a video or audio message to send with your gift. You can record a message up to 30 seconds long, and we will include it on your message card.</p>
							</div>
							<div
								className="message-check"
								onClick={() => {
									setOpenVideoMessage(!openVideoMessage);
								}}
							>
								<div className="checkmark">
									<FontAwesomeIcon icon={faCheck} />
								</div>
								<strong className="price">FREE</strong>
							</div>

							{openVideoMessage && <RecordAMessageSection />}
						</div>
					</>
				)}
			</div>

			<PersonalisedCardModal
				personalisedCardLink={personalisedCardLink}
				isPersonalisedCardModalOpen={isPersonalisedCardModalOpen}
				setIsPersonalisedCardModalOpen={setIsPersonalisedCardModalOpen}
				personalisedCardCallback={(printzwareId, editLink) => {
					ChangeCardType("personalised", printzwareId);
					setPersonalisedCardLink(editLink);
				}}
			/>
		</section>
	);
};

const PersonalisedCardModal = ({ personalisedCardLink, isPersonalisedCardModalOpen, setIsPersonalisedCardModalOpen, personalisedCardCallback }) => {
	const iframeRef = useRef(null);

	useEffect(() => {
		const handleIframeMessage = (event) => {
			if (event.source !== iframeRef.current?.contentWindow) return;
			const data = JSON.parse(event.data);
			if (data.event === "response") personalisedCardCallback(data.printzware_id, data.edit_url);
			if (data.event === "close") setIsPersonalisedCardModalOpen(false);
		};

		window.addEventListener("message", handleIframeMessage);
		return () => window.removeEventListener("message", handleIframeMessage);
	}, []);

	return isPersonalisedCardModalOpen ? (
		<div className={`personalised-card-modal-wrapper ${isPersonalisedCardModalOpen ? "open" : ""}`}>
			<iframe
				title="personalised-card-modal"
				className={`personalised-card-modal ${isPersonalisedCardModalOpen ? "open" : ""}`}
				src={personalisedCardLink}
				ref={iframeRef}
			/>
		</div>
	) : null;
};
